<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <h3 class="ml-2">Thông tin hạng mục</h3>
                </div>
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Tên hạng mục"
                                  v-model="dailyWorkDetail.name"
                                  v-validate="'required'" name="dailyWorkDetailName"/>
                        <span class="text-danger text-sm">{{ errors.first('dailyWorkDetailName') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Phương pháp"
                                  v-model="dailyWorkDetail.methodCheck"
                                  v-validate="'required'" name="methodCheck"/>
                        <span class="text-danger text-sm">{{ errors.first('methodCheck') }}</span>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-2/3 w-full mt-2">
                        <vs-textarea class="w-full" label="Yêu cầu công việc"
                                     v-model="dailyWorkDetail.completionCriteria"
                                     v-validate="'required'"
                                     name="metadata"/>
                        <span class="text-danger text-sm">{{ errors.first('completionCriteria') }}</span>
                    </div>
                </div>
                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.go(-1)">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {
            isUpdate: false,
            dailyWorkDetail: {},
        }
    },
    created() {
        let id = this.$route.query.id;
        if (id) {
            setTimeout(() => {
                this.$vs.loading();
                this.$crm.get('/work-detailed/find-by-id/' + id).then((response) => {
                    this.$vs.loading.close();

                    this.dailyWorkDetail = response.data;
                    this.isUpdate = true;
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }, 0)
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;
                let url = this.isUpdate ? `/work-detailed/update/${this.dailyWorkDetail.id}` : `/work-detailed/create`,
                    data = this.dailyWorkDetail;
                if (this.isUpdate) delete data.id;
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} hạng mục thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$router.push(`/user/daily-work-detail`).catch(() => {
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        }
    },
}
</script>

<style scoped>

</style>
